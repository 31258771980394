import * as React from 'react'
import Layout from 'components/Layout'
import { Placeholder } from 'components/Common'

const IndexPage = () => (
  <Layout title="Black Swan Digital | The integrated web development agency">
    <Placeholder />
  </Layout>
)

export default IndexPage
